export const songDetails = {
    "song_id": null,
    "song_title": null,
    "song_is_do_not_use": 0,
    "is_PD": 0,
    "is_active":1,
    "is_arrangement": 0,
    "is_all_promo_use_restricted": 0,
    "is_limited_promo_use": 0,
    "is_library": 0,
    "is_coa": 0,
    "is_contains_simple": 0,
    "is_studio_owned": 0,
    "notes": null,
    "writers_list": [],
    "licensors_list": [],
    "performers_list": []
}

export const publicDomainSongDetails = {
    "song_id": null,
    "song_title": null,
    "song_is_do_not_use": 0,
    "is_PD": 0,
    "notes": null,
    "pd_genre": [],
    "pd_composer": []
}

export const writersList = {
    "song_writer_id": null,
    "writer_id": null,
    "writer_name": null,
    "writer_is_active": 0,
    "share_percentage":0,
    "affiliation_id": null,
    "affiliation_name":null,
    "song_writer_is_delete": 0
}

export const musicTypes = {
    "song_pd_genre_id": null,
    "public_domain_genre_id": null,
    "public_domain_genre": null,
    "pd_genre_is_delete": 0,
    "index": 1,
}

export const LicenseHeading = {
    headings: [
        {
            headingLabel: " ",
            inputType: "icon",
            icon: "",
            width: "3%"
        },
        {
            headingLabel: "Licensor",
            icon: "",
            width: "48%",
            inputType: "searchselect",
            selectOptions: [],
        },
        {
            headingLabel: "Inactive",
            icon: "",
            width: "14%",
            inputType: "checkbox",
        },

        {
            headingLabel: "Share",
            icon: "",
            width: "12%",
            inputType: "text"
        },
        {
            headingLabel: "SM",
            icon: "",
            width: "8%",
            inputType: "checkbox",
        },
    ],
    dataNodes: ["song_licensor_id","licensor", "licensor_is_active", "licensor_share", "is_SM"],
    searchFields: [{
        label: "licensor",
        hyperlink: true
    }],
    actions: ["pen","trash-alt"],
    changeColourToGreen: ["licensor_share"],
    primaryKey: "song_licensor_id",
    inlineEdits: [
        {
            dataNode: "song_licensor_id",
            width: "3%",
            icon:"pen-square",
            resetField: 'licensor',
            fieldDisabled: false,
        },
        {
            dataNode: "licensor",
            width: "45%"
        },
        {
            dataNode: "licensor_is_active",
            width: "8%"
        },
        {
            dataNode: "licensor_share",
            width: "12%",
            validateMethod:"validateNumDotOnly",
            validateMessage : 'Please enter numbers'
        },
        {
            dataNode: "is_SM",
            width: "5%"
        },
    ],
    isClearFields: true,
    nodeFields: [
        { column: "licensor_is_active", icon: "check" },
        { column: "is_SM", icon: "check" },
        { column: "song_licensor_id",icon: "pen-square"},
    ]
}

export const CueSheetHeading = {
    headings: [
        {
            headingLabel: " ",
            inputType: "icon",
            icon: "",
            width: "3%"
        },
        {
            headingLabel: "Cue Sheet Publisher",
            icon: "",
            width: "50%",
            inputType: "searchselect",
            selectOptions: [],
        },
        {
            headingLabel: "Affiliation",
            icon: "",
            width: "20%",
            inputType: "select",
            selectOptions: [],
        },
        {
            headingLabel: "Share",
            icon: "",
            width: "12%",
            inputType: "text"
        },
    ],
    dataNodes: ["song_cue_sheet_publisher_id","cue_sheet_publisher", "affiliation", "cue_sheet_share"],
    searchFields: [
        {
            label: "cue_sheet_publisher",
            hyperlink: false
        }
    ],
    actions: ["pen","trash-alt"],
    changeColourToGreen: ["cue_sheet_share"],
    primaryKey: "song_cue_sheet_publisher_id",
    inlineEdits: [
        {
            dataNode: "song_cue_sheet_publisher_id",
            width: "3%",
            icon:"pen-square",
            resetField: 'cue_sheet_publisher',
            fieldDisabled: false,
        },
        {
            dataNode: "cue_sheet_publisher",
            width: "45%"
        },
        {
            dataNode: "affiliation",
            width: "18%"
        },
        {
            dataNode: "cue_sheet_share",
            width: "12%",
            validateMethod:"validateNumDotOnly",
            validateMessage : 'Please enter numbers'
        }
    ],
    isClearFields: true,
    nodeFields: [{ column: "inactive", icon: "check" },  { column: "song_cue_sheet_publisher_id",icon: "pen-square"},]
}

export const PerformerHeading = {
    headings: [
        {
            headingLabel: " ",
            inputType: "icon",
            icon: "",
            width: "4%"
        },
        {
            headingLabel: "Performer",
            icon: "",
            width: "60%",
            inputType: "searchselect",
            selectOptions: [],
        },
        {
            headingLabel: "Do Not Use",
            icon: "",
            width: "20%",
            inputType: "checkbox"
        },
    ],
    dataNodes: ["song_performer_id","performer", "performer_is_do_not_use"],
    searchFields: [
        {
            label: "performer",
            hyperlink: true
        }
    ],
    actions: ["pen","trash-alt"],
    changeColourToGreen: ["share"],
    primaryKey: "song_performer_id",
    inlineEdits: [
        {
            dataNode: "song_performer_id",
            width: "3%",
            icon:"pen-square",
            resetField: 'performer',
            fieldDisabled: false,
        },
        {
            dataNode: "performer",
            width: "59%"
        },
        {
            dataNode: "performer_is_do_not_use",
            width: "22%"
        },
    ],
    isClearFields: true,
    nodeFields: [{ column: "performer_is_do_not_use", icon: "check" },  { column: "song_performer_id",icon: "pen-square"}]
}

export const LicensorsWayneHeading = {
    headings: [
        {
            headingLabel: " ",
            inputType: "icon",
            icon: "",
            width: "3%"
        },
        {
            headingLabel: "Licensor",
            icon: "",
            width: "39%",
            inputType: "searchselect",
            selectOptions: [],
        },
        {
            headingLabel: "Inactive",
            icon: "",
            width: "14%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Share",
            icon: "",
            width: "11%",
            inputType: "text"
        },
        {
            headingLabel: "Do Not Use",
            icon: "",
            width: "19%",
            inputType: "checkbox"
        },
    ],
    dataNodes: ["performer_licensor_id","performer_licensor", "performer_licensor_is_active", "performer_licensor_share", "performer_licensor_is_do_not_use"],
    searchFields: [
        {
            label: "performer_licensor",
            hyperlink: false
        }
    ],
    actions: ["pen","trash-alt"],
    changeColourToGreen: ["performer_licensor_share"],
    primaryKey: "performer_licensor_id",
    inlineEdits: [
        {
            dataNode: "performer_licensor_id",
            width: "3%",
            icon:"pen-square",
            resetField: 'performer_licensor',
            fieldDisabled: false,
        },
        { dataNode: "performer_licensor", width: "37%" },
        { dataNode: "performer_licensor_is_active", width: "8%" },
        { dataNode: "performer_licensor_share", width: "12%", validateMethod:"validateNumDotOnly",validateMessage : 'Please enter numbers' },
        { dataNode: "performer_licensor_is_do_not_use", width: "15%" }
    ],
    isClearFields: true,
    nodeFields: [
        { column: "performer_licensor_is_active", icon: "check" },
        { column: "performer_licensor_is_do_not_use", icon: "check" },
        { column: "performer_licensor_id",icon: "pen-square"},
    ]
}

export const PdComposers = {
    headings: [
        {
            headingLabel: "Composer",
            icon: "",
            width: "62%",
            inputType: "searchselect",
            selectOptions: [],
        },
        {
            headingLabel: "Year",
            icon: "",
            width: "10%",
            inputType: "text"
        },
        {
            headingLabel: "Music",
            icon: "",
            width: "8%",
            inputType: "checkbox",
        },
        {
            headingLabel: "Lyrics",
            icon: "",
            width: "8%",
            inputType: "checkbox",
        },
    ],
    dataNodes: ["pd_composer", "year", "is_music", "is_lyrics"],
    searchFields: [{
        label: "pd_composer",
        hyperlink: false
    }],
    actions: ["pen"],
    primaryKey: "song_pd_composer_id",
    inlineEdits: [
        {
            dataNode: "pd_composer",
            width: "60.5%"
        },
        {
            dataNode: "year",
            width: "9.5%",
            validateMethod:"validateNumbersOnly",
            validateMessage : 'Please enter numbers'
        },
        {
            dataNode: "is_music",
            width: "7%"
        },
        {
            dataNode: "is_lyrics",
            width: "11%"
        },
    ],
    isClearFields: true,
    nodeFields: [
        { column: "is_music", icon: "check" },
        { column: "is_lyrics", icon: "check" }
    ]
}

export const clearReacordList = [
    {
        id: 1,
        program: "Shameless",
        season: "2017-2018",
        episode: "U13.14552",
        composition: "[Composition Name] by [Performer(s)]",
        rights: "[Rights]",
        fee: "$[Total Fee]",
        status: "Submitted"
    },
    {
        id: 2,
        program: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        composition: "[Composition Name] by [Performer(s)]",
        rights: "[Rights]",
        fee: "$[Total Fee]",
        status: "Submitted"
    },
    {
        id: 3,
        program: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        composition: "[Composition Name] by [Performer(s)]",
        rights: "[Rights]",
        fee: "$[Total Fee]",
        status: "Submitted"
    },
    {
        id: 4,
        program: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        composition: "[Composition Name] by [Performer(s)]",
        rights: "[Rights]",
        fee: "$[Total Fee]",
        status: "Submitted"
    },
    {
        id: 5,
        program: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        composition: "[Composition Name] by [Performer(s)]",
        rights: "[Rights]",
        fee: "$[Total Fee]",
        status: "Submitted"
    },
    {
        id: 6,
        program: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        composition: "[Composition Name] by [Performer(s)]",
        rights: "[Rights]",
        fee: "$[Total Fee]",
        status: "Submitted"
    }
]
export const clearReacordHeading = {
    headings: [
        {
            headingLabel: "Program",
            icon: "",
            width: "15%",
        },
        {
            headingLabel: "Season",
            icon: "",
            width: "10%"
        },

        {
            headingLabel: "Episode",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "Composition",
            icon: "",
            width: "25%"
        },
        {
            headingLabel: "Rights",
            icon: "",
            width: "17%"
        },
        {
            headingLabel: "Fee",
            icon: "",
            width: "8%"
        },
        {
            headingLabel: "Status",
            icon: "",
            width: "6%"
        },
        {
            headingLabel: "",
            icon: "",
            width: "3%"
        }
    ],
    dataNodes: ["show_name", "season_name", "episode_title", "song_title", "rights", "fee", "status", ""],
    actions: ["share"],
    hyperlinks: ["show_name", "season_name", "episode_title"]
}