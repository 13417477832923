import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import Tabs from '../../SharedComponents/Tabs/Tabs';
import { EditSongTabList, AddSongTabList } from '../../../constants/TabList';
import EditSongDetails from './EditSongDetails';
import EditSongClearanceRecord from './EditSongClearanceRecord';
import { withUserContext } from '../../../contexts/UserContext';
import './EditSong.scss';
export default withUserContext(class EditSong extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
        }
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue
        })
    }



    handleText = () => {
        switch (this.state.tabValue) {
            case 1: return "EditPayeeDetails"
            case 2: return "ClearanceRecords"
        }
    }

    handleSubmit = () => {
        switch (this.state.tabValue) {
            case 1: return this.props.EditPayeeDetails
            case 2: return this.props.ClearanceRecord
        }
    }

    render() {
        return (
            <MDBContainer fluid className="edit-song-container">
                <MDBRow className="tab-content">
                    <MDBCol md={10}>
                        <Tabs
                            tabList={(this.props?.mode === "New" || this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Supervisor') )? AddSongTabList : EditSongTabList}
                            value={this.state.tabValue}
                            onChange={(e, v) => this.handleTabChange(v)}
                        />
                    </MDBCol>
                </MDBRow>
                {/* <div disabled={!editSongPermission}> */}
                <div>
                    <MDBCol className={this.state.tabValue == 1 ? "d-block" : "d-none"}>
                        <EditSongDetails
                            songId={this.props?.songId}
                            mode={this.props?.mode}
                            fieldChanged={this.props?.fieldChanged}
                            setPostFlag={this.props?.setPostFlag}
                            handleClose={this.props?.handleClose}
                            notificationComponent={this.props?.notificationComponent}
                            toggleRefreshPage={this.props?.toggleRefreshPage}
                            toggleRefreshMainTitleLicensor={this.props?.toggleRefreshMainTitleLicensor}
                            isMainTitle={this.props?.isMainTitle}
                            songModalPermissions={this.props?.songModalPermissions}
                            origin="EditSongs"
                            handleFilterSearch={this.props.handleFilterSearch}
                        />
                    </MDBCol>
                    <MDBCol className={this.state.tabValue == 2 ? "d-block" : "d-none"}>
                        <EditSongClearanceRecord
                            fieldChanged={this.fieldChanged}
                            setPostFlag={this.setPostFlag}
                            songId={this.props?.songId}
                            division_id={this.props?.division_id}
                        // commonPermissions={this.props?.commonPermissions}
                        />
                    </MDBCol>
                </div>
            </MDBContainer>
        );
    }
})